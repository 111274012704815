#zmmtg-root.show-zoom {
  display: block;
}

/* .footer__btns-container {
  display: none !important;
} */

/* #moreButton {
  display: none !important;
} */
.footer__leave-btn-container {
  display: none !important;
}
.footer__leave-btn {
  display: none !important;
}
.footer__inner {
  justify-content: center !important;
}
#audioOptionMenu {
  display: none !important;
}
#videoOptionMenu {
  display: none !important;
}
