.react-input-emoji--input {
  max-height: 40px !important;
  z-index: 0 !important;
}
.emoji-mart {
  width: 250px !important;
}
.react-emoji-picker--wrapper__show {
  height: 320px !important;
  margin-bottom: 20px;
}
.react-input-emoji--button svg {
  fill: white !important;
}
.react-input-emoji--placeholder {
  font-size: 13px !important;
}
@media only screen and (max-width: 576px) {
  .react-input-emoji--input {
    max-height: 30px !important;
  }
  .react-input-emoji--placeholder {
    font-size: 10px !important;
  }
}
