@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
html,
body {
  font-family: "Poppins", sans-serif;
  overflow: auto !important;
  min-width: 0 !important;
  min-height: 0 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

#zmmtg-root {
  display: none;
}
